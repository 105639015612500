'use client'

import { SelectionItem } from '../../../domain/selectionItem'
import { useIsomorphicLayoutEffect } from '../../../core/hook/useIsomorphicLayout'
import { initSliderForPushSmall } from '../../utils/PushSmall.utils'
import { Tag } from '../Tag/tag'
import { formatPriceToShowLocal } from '../../utils/price.utils'
import { gtmCategory, gtmPush } from '../../utils/gtmHelpers'
import { MouseEventHandler, useContext, useEffect, useState } from 'react'
import ModalCart from '../Modal/ModalCart'
import { AlertNotificationContext } from '../../context/AlertNotificationContext'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import useAddProductToCart from '../../../core/hook/cartHooks/useAddProductToCart'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import Image from '../Image/Image'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { handleError } from '../../../core/hook/utils'
import Link from '../Link/Link'
import { useIsMobileOrTablet } from '../../utils/screen.utils'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { getIsCoteIvoire } from '../../utils/locale.utils'
import Loader from '../Loader/Loader'

export const PushSmall = ({
  id,
  mediaGallery,
  name,
  gtm_name,
  title,
  titleDescription,
  priceRange,
  urlKey,
  parentClassNameList,
  colorVariations,
  isExcluWeb,
  isNew,
  isIconique,
  mariage,
  typename,
  customPrice,
  directAddToCart,
  couleurMetalExtended,
  position,
  stockStatusProduct,
  isLoading,
  gtm_categories,
  sku,
  listId,
  listName,
}: SelectionItem & {
  position?: number
  stockStatusProduct?: boolean
  isHome?: boolean
  isLoading?: boolean
  listId?: string
  listName?: string
}) => {
  const product = {
    url_key: urlKey,
    price_range: priceRange,
    __typename: typename,
    name,
    gtm_name,
    id,
    sku,
    gtm_categories: gtm_categories,
  } as Product

  const classNameSwiper = `push-small-swiper-${id}`
  const classNameSwiperPagination = `push-small-swiper-pagination-${id}`
  const t = useTranslation()
  const isTablet = useIsMobileOrTablet()
  const [isMounted, setMounted] = useState(false);

  const addProductToCart = useAddProductToCart()
  const { addNotification } = useContext(AlertNotificationContext)
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType

  const { storeConfig } = useContext(ConfigContext)

  useEffect(() => {
    setMounted(true);
  }, [])

  const gtmProductPush = (type: string) => {
    const gtmCategoryObj = gtmCategory(product.gtm_categories)
    const listIdValue = listId ?? 'categorie'
    const listNameValue = listName ?? "Catégorie d'Articles"

    gtmPush({
      event: type,
      ecommerce: {
        item_list_id: listIdValue,
        item_list_name: listNameValue,
        items: {
          item_name: product.gtm_name,
          item_id: product.sku,
          price: product.price_range?.minimum_price.regular_price.value,
          item_brand: 'Messika',
          index: position ?? '',
          item_variant: product.variants ?? '',
          ...gtmCategoryObj,
        },
      },
    })

    const productValues = {
      position: position,
      listId: listIdValue,
      listName: listNameValue,
    }

    localStorage.setItem('productValues', JSON.stringify(productValues))
  }

  const callbackAddProduct = (contextResponse) => {
    if (!contextResponse.success) {
      addNotification(contextResponse.error)
      return
    }

    const uuid = crypto.randomUUID()
    addModal({
      uuid,
      component: <ModalCart uuid={uuid} onClose={() => removeModal(uuid)} />,
    })
  }

  const handleClickAddToCart = () => {
    if (product) {
      const sku = urlKey?.split('-').slice(-2).join('-').toUpperCase()
      const productWithSKU = { ...product, sku: sku, position: position }

      gtmProductPush('add_to_cart')

      addProductToCart(productWithSKU, undefined, undefined, undefined)
        .then(callbackAddProduct)
        .catch((error) => {
          handleError(error, false)
        })
    }
  }

  const handleClick = () => {
    gtmProductPush('select_item')
  }


  useIsomorphicLayoutEffect(() => {
    try {
      const swiper = initSliderForPushSmall(
        classNameSwiper,
        classNameSwiperPagination,
        (mediaGallery?.length ?? 0) > 1,
        parentClassNameList,
      )

      return () => {
        try {
          swiper?.destroy?.()
        } catch (error) {
          // TODO workaround
          handleError(error, false)
        }
      }
    } catch (error) {
      // TODO workaround
      handleError(error, false)
    }
  }, [parentClassNameList, classNameSwiper, classNameSwiperPagination, mediaGallery?.length])

  const onClickIconCartForMobile: MouseEventHandler<HTMLDivElement> = (e) => {
    if (directAddToCart) {
      e.preventDefault()
      e.stopPropagation()
      handleClickAddToCart()
    }
  }

  const local = useCurrentLocale()

  const formatedPrice = !getIsCoteIvoire(local)
    ? formatPriceToShowLocal(
        priceRange?.minimum_price.regular_price.value || 0,
        priceRange?.minimum_price.regular_price.currency || 'EUR',
        local,
      )
    : ' '

  if (mediaGallery && mediaGallery.length > 1) {
    const imageTagSmall = mediaGallery.findIndex((item) => {
      if (item && item.roles) {
        return item.roles.includes('small_image')
      }
      return false
    })

    if (imageTagSmall !== -1) {
      const [smallImage] = mediaGallery.splice(imageTagSmall, 1)
      mediaGallery.unshift(smallImage)
    }
  }

  return (
    <div className='selection-item'>
      <article
        className='card'
        data-product-id={product.id}
        data-product-type={product.__typename}
        data-product-name={product.name}
        data-product-price={product.price_range?.minimum_price.regular_price.value?.toString()}
        data-product-category={product.gtm_categories}
        data-product-brand={couleurMetalExtended?.label}
        data-product-position={position}>
        <Link href={urlKey ?? ''} onClick={handleClick}>
          <figure>
            {!isTablet && isMounted ? (
              <Tag isExcluWeb={isExcluWeb ?? 0} isNew={isNew ?? 0} isIconique={isIconique ?? 0} />
            ) : null}

            {mediaGallery && mediaGallery.length > 1 ? (
              <div className='img-container'>
                <div className={`swiper push-small-swiper ${classNameSwiper}`}>
                  <div className='swiper-wrapper push-small-swiper-wrapper'>
                    {mediaGallery?.map(
                      (image) =>
                        image?.small_image  && (
                          <div className='swiper-slide' key={`slide-${image.url}`}>
                            {stockStatusProduct && !isLoading ? (
                              <div
                                className='selection-item-cart'
                                onClick={onClickIconCartForMobile}>
                                <Icon iconName={Icons.CART} />
                              </div>
                            ) : null}

                            {isTablet && isMounted ? (
                              <Tag
                                isExcluWeb={isExcluWeb ?? 0}
                                isNew={isNew ?? 0}
                                isIconique={isIconique ?? 0}
                              />
                            ) : null}

                            <picture>
                              <Image src={image.small_image} alt={image.label} size='l' />
                            </picture>
                          </div>
                        ),
                    )}
                  </div>
                </div>
                <div className={`custom-slider-pagination ${classNameSwiperPagination}`} />
              </div>
            ) : (
              <div className='img-container'>
                {mediaGallery?.map((image) => (
                  <picture key={`media-${image.url}`}>
                    <Image src={image.small_image} alt={image.label} size='l' />
                  </picture>
                ))}
              </div>
            )}

            <figcaption>
              {name && <h3 className='title-h5' dangerouslySetInnerHTML={{ __html: name }} />}
              {title && (
                <p className='push-description' dangerouslySetInnerHTML={{ __html: title }} />
              )}
              {titleDescription && (
                <p
                  className='push-description'
                  dangerouslySetInnerHTML={{ __html: titleDescription }}
                />
              )}

              {colorVariations && colorVariations >= 2 ? (
                <p className='push-color'>
                  {t('%1 colors', { variables: { 0: colorVariations } }, true)}
                </p>
              ) : !colorVariations || colorVariations < 2 ? (
                <p className='push-color'>{t('1 color', {}, true)}</p>
              ) : null}

              {storeConfig.display_prices === 1 && (
                <>
                  {mariage === 1 ? (
                    <>
                      {/* Si Mariage & "display_custom_price" -> affichage du prix "à partir de" */}
                      {customPrice === 1 ? (
                        <p className='push-price'>
                          <span className='extra'>{t('As low as', {}, true)}</span>
                          {formatedPrice}
                        </p>
                      ) : (
                        <p className='push-price'>
                          {/* Si Mariage et "display_custom_price" === false -> affichaged'un libellé "prix sur demande" */}
                          <span className='extra'>{t('Price on demand', {}, true)}</span>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Si pas Mariage & "Bundle" -> affichage de "à partir de" + prix*/}
                      {typename === 'BundleProduct' ? (
                        <p className='push-price'>
                          <span className='extra'>{t('As low as', {}, true)}</span>
                          {formatedPrice}
                        </p>
                      ) : (
                        <p className='push-price'>
                          {/* Si pas Mariage & "Bundle" === false -> affiche du prix simplement*/}
                          {formatedPrice}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </figcaption>
          </figure>
        </Link>
      </article>
      {!isTablet && isMounted ? (
        <>
          {stockStatusProduct ? (
            <>
              {isLoading ? (
                <Loader small />
              ): (
              directAddToCart ? (
                <button className='button black' onClick={handleClickAddToCart}>
                  {t('Add to Cart', {}, true)}
                </button>
              ) : (
                <Link href={urlKey ?? ''} className='button black' onClick={handleClick}>
                  {t('Add to Cart', {}, true)}
                </Link>
              ))}
            </>
          ) : (
            <Link href={urlKey ?? ''} className='button black' onClick={handleClick}>
              {t('global-cta-discover', {}, true)}
            </Link>
          )}
        </>
      ) : null}
    </div>
  )
}
